import { FunctionComponent } from "react";
import generalStyles from '../../styles/general.module.scss';
import FooterStyle from '../../styles/footer.module.scss';

interface FooterProps {
    content: string
}
 
const Footer: FunctionComponent<FooterProps> = ({content}) => {
    const sanetizeText = (text : string): string => {
        var htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
        const noSpecialChars = text.replace(htmlRegexG, "");
        return noSpecialChars;
    }
    return (
        <div className={generalStyles.footer}>
            <span className={FooterStyle.messageFooter}>{sanetizeText(content)}</span>
            <a className={FooterStyle.phoneCall} href="tel:050-7200440">050-7200440</a>
        </div>
        );
}
 
export default Footer;

