import { FunctionComponent } from "react";
import itemStyle from '../../styles/item.module.scss';

interface AdminItemProps {
    item: any;
    availableAction: Function;
    index: number;
}
 
const AdminItem: FunctionComponent<AdminItemProps> = ({item, availableAction, index}) => {

    
    return (
    <div className={`${itemStyle.adminGridItem} ${item.term_group ? itemStyle.available : ''}`}>
        <span>{item.name}</span>
        {<img src={require(`../../assets/${item.slug}-img.png`)} alt={item.name} />}
        <button onClick={() => availableAction(index)} type="button" style={{position: 'absolute', bottom: 10, left: 10, zIndex: 20}}
        className={itemStyle.toggleItem}></button>
    </div>
    );
}
 
export default AdminItem;