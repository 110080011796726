import React, { FunctionComponent, useContext, useEffect, useRef, useState } from "react";
import generalStyles from '../../styles/general.module.scss';
import loginStyles from '../../styles/login.module.scss';
import allTags from '../../assets/data/tags.json';
import AdminItem from "../adminItem/AdminItem";
import { Iitem } from "../../assets/models/item.model";
import Header from "../header/Header";
import { PostDataContext } from "../../context/PostDataContext";
interface LoginProps {
    
}

const url = 'https://starsfarm.co.il/wp-json/jwt-auth/v1/token';

const putUrl = 'https://starsfarm.co.il/wp-json/wp/v2/posts/4594';

const Login: FunctionComponent<LoginProps> = () => {
    
    const emailRef: any = useRef(null);
    const passwordRef: any = useRef(null);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [token, setToken] = useState('');
    const [tags, updateTags] = useState<any>(allTags);
    const { postData } = useContext(PostDataContext);

    const submitLoginForm = (event: any) => {

        event.preventDefault();
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: emailRef.current.value, password: passwordRef.current.value})
        })
        .then(response => response.json())
        .then(response => {
            
            if(response.token) {
                setLoggedIn(true);
                setToken(response.token);
            }
            return;
        })
    }
    const toggleItem = (index: any) => {
        tags[index].term_group =  tags[index].term_group ? 0 : 1;
        updateTags([...tags])
    }

    const saveTags = () => {
        fetch(putUrl, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ tags: tags.filter((item: any) => item.term_group).map((it: any) => it.term_id)})
        })
        .then(response => response.json())
        .then(response => {
            
            if(response.token) {
                setLoggedIn(true);
            }
            setTimeout(() => {
                setIsSave(true);
            }, 1000);
            return;
        })
    }

    
    const setTagsStatus = () => {
        postData['tags'].map((itemId: number) =>  {
            const availableItem = tags.find((item: any) => itemId === item.term_id);
            availableItem.term_group = 1;
            return availableItem;
        });
    }

    useEffect(() => {
        setTagsStatus();
    },[])
   
   return(
   <div style={{flexDirection: 'column'}} className={generalStyles.wrapperCenterd}>
    {isLoggedIn}
    <Header title={''} />
    {!isLoggedIn && <form className={loginStyles.loginForm}>
    <div className={generalStyles.formGrop}>
        <label htmlFor="userName">User name</label>
        <input ref={emailRef} id="userName" type="text" placeholder="User name" />
    </div>
    <div className={generalStyles.formGroup}>
        <label htmlFor="Password">Password</label>
        <input ref={passwordRef} id="Password" type="password" placeholder="Password" />
    </div>
    <button onClick={(event) => submitLoginForm(event)} type="submit">Login</button>
   </form>
    }
    {isLoggedIn && 
    <>
        <div className={loginStyles.loginForm} style={{'textAlign':'center', 'maxWidth': 960, 'width': '100%'}}>
            { tags && tags.map((item: any, i: number) => <AdminItem availableAction={toggleItem} index={i} key={i} item={item}></AdminItem>) } 
        </div>
    <div className={loginStyles.footerAdmin + " " + generalStyles.footer}>
        { !isSave ? <button type="button" onClick={saveTags}>save</button> : <button type="button" disabled={true}>changes has been saved</button>}
    </div>
    </>
    }
   </div>
   )
}
 
export default Login;