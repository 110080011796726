import { createContext, useState } from "react";
import useFetch from "../hooks/useFetch";


export const PostDataContext = createContext<any>(null);

// create context for getting all data from API , 

// save post data : tags , title, description
// save all tags data : 
const postDataUrl = 'https://starsfarm.co.il/wp-json/wp/v2/posts/4594';


export const PostDataProvider  = ({ children }: any) => {
    const { data, error } = useFetch(postDataUrl);

  return (
    <PostDataContext.Provider value={{postData:data}}>
     {children}
    </PostDataContext.Provider>
  );
};