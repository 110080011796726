export interface Iitem {
    id: number;
    name: string;
    img: string;
    note?: string;
    description?: string;
    status?: EAvalabilityStatus;
}


export enum EAvalabilityStatus {
    AVAILABLE = 'יש במלאי',
    ALMOST = 'עומד להסתיים',
    NONE = 'אזל המלאי'
}


