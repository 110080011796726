import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Iitem } from "../assets/models/item.model";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import List from "../components/list/List";
import useFetch from "../hooks/useFetch";
import generalStyles from '../styles/general.module.scss';

interface MainProps {
    postData: any
}
const url = `https://starsfarm.co.il/wp-json/wp/v2/tags?per_page=100&include=`;

interface Tag {
    id: number;
    name: string;
    slug: string;
    description: string;
    link: string;
  }

const Main: FunctionComponent<MainProps> = ({postData}: any) => {
    const { data, error } = useFetch<Tag[]>(url+postData?.tags.toString());
    const [dataList, setDataList] = useState<Iitem[] | undefined>();
    
    useEffect(() => {
        const mapData = data && data.filter((item: Tag) => item.slug.includes('availability')).map((itm: Tag) => {
            return ({
                id: itm.id,
                name: itm.name,
                img: itm.slug + '-img.png',
                description: itm.description
            })
        });
        setDataList(mapData);
    }, [data]);

    if (error) return <p>There is an error.</p>
    if (!data) return <div className={generalStyles.loading}>כמה רגעים...</div>

    return (
        <div className={generalStyles.container}>                
            <Header title={postData.title.rendered} />
            <List dataList = {dataList} />
            <Footer content={postData.content.rendered} />
        </div>
        );
}
 
export default Main;

