import React, { useContext, useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './layout/main';
import { PostDataContext } from './context/PostDataContext';
import { Outlet, Routes, Route, BrowserRouter as Router, useSearchParams } from "react-router-dom";
import Login from './components/login/Login';
import generalStyles from './styles/general.module.scss';

function App() {
  const { postData } = useContext(PostDataContext);
  const [q, setQ] = useState<string | null>(null);
  useEffect(() => {
    setQ(window.location.search);
  },[q]);

  if (!postData) return <div className={generalStyles.loading}>כמה רגעים...</div>; 

  return (
    <>
     {q === '?login' ? <Login /> : <Main postData={postData} />}
    </>
  );
}

export default App;
  