import { FunctionComponent, useEffect } from "react";
import { Iitem } from "../../assets/models/item.model";
import generalStyles from '../../styles/general.module.scss';
import Item from "../item/Item";

interface ListProps {
    dataList: Iitem[] | undefined
}

const List: FunctionComponent<ListProps> = ({ dataList }) => {
    return (
        <ul style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center'
        }}>
            {dataList && dataList.map((item: Iitem, index: number) => <div className={generalStyles.flexCenter} key={item.id}>
                <li style={{ margin: '0 20px' }}><Item item={dataList[index]} /></li>
            </div>)}
        </ul>
    );
}

export default List;

