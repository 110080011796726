import { FunctionComponent } from "react";
import generalStyles from '../../styles/general.module.scss';
import headerStyles from '../../styles/header.module.scss';
import logo from '../../assets/logo-meshek.png';
interface HeaderProps {
    title: string;
}

const Header: FunctionComponent<HeaderProps> = ({ title }) => {

    const content = {
        title: 'משק הכוכבים',
        subtitle: title
    }

    const getDateOfToday = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm: string | number = today.getMonth() + 1; // Months start at 0!
        let dd: string | number = today.getDate();

        if (dd < 10) { dd = '0' + dd };
        if (mm < 10) { mm = '0' + mm };

        const formattedToday = dd + '/' + mm + '/' + yyyy;
        return formattedToday
    }

    return (
        <div className={generalStyles.header}>
            <div className={generalStyles.headerDescription}>
                <div className={generalStyles.logoContainer}>
                   <a href="https://starsfarm.co.il">
                   <img  src={logo} alt="משק הכוכבים לוגו" />
                   </a>
                    <span style={{ fontSize: '24px', fontWeight: 700 }}>{content.title}</span>
                </div>
                <span style={{ fontSize: '36px', fontWeight: 700 }}>{content.subtitle}</span>
                <span className={headerStyles.date}>{getDateOfToday()}</span>
                <span className={headerStyles.importantContent}>
                    המלאי המוצג, מעודכן לתחילת היום ועלול להשתנות במהלכו. כמו כן, הכמויות לרוב לא גדולות אך מגוון מאד, יספיק לכם להכין סלט זה בטוח 
                </span>
                <div style={{ maxWidth: '300px' }} className={generalStyles.saparatorVerrtical}></div>
            </div>
        </div>
    );
}

export default Header;

