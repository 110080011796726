import { FunctionComponent } from "react";
import { Iitem } from "../../assets/models/item.model";
import itemStyle from '../../styles/item.module.scss';

interface ItemProps {
    item: Iitem
}

const Item: FunctionComponent<ItemProps> = ({ item }) => {
    return (item && <div className={itemStyle.itemContainer}>
        <span>{item.name}</span>
        <img src={require(`../../assets/${item.img}`)} alt={item.name} />
    </div>
    );
}

export default Item;